import Header from '../components/Header'
import Footer from '../components/Footer'
import img from '../images/projectsDetails/img.png'
import avater from '../images/common/avater.png'
import { Link } from 'gatsby'

const ProjectsDetails: React.FC = () => {
  return (
    <>
      <div className="sm:hidden">
        <Header />
      </div>
      <div className="text-sm font-regularText">
        <div className="pt-20 mx-auto bg-[url('../images/projectsDetails/bgBanner.png')] bg-no-repeat bg-[length:100%_517px] bg-[#F3F7FA] pb-[146px] sm:pt-0 sm:bg-[length:auto_319px] bg-right-top sm:pb-[28px]">
          <div className="mx-auto mt-[54px] max-w-7xl md:px-16 md:w-auto sm:px-4 sm:mt-0 overflow-hidden">
            {/* Mobile Page Title */}
            <div className="hidden sm:grid h-30px grid-cols-3 mt-[25px] items-center">
              <Link to="/projects" className="w-6 h-6 text-[#000]">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </Link>
              <div className="text-[22px] leading-[30px] font-bold whitespace-nowrap">
                Coin details
              </div>
            </div>
            <div className="flex items-center justify-between sm:mt-[52px]">
              <div>
                <div className="flex items-center">
                  <img
                    src={avater}
                    alt="avater"
                    className="h-[54px] w-[54px]"
                  />
                  <h3 className="ml-3 font-boldText text-[30px] leading-[52px]">
                    BNB Chain
                  </h3>
                </div>
                <div className="text-[30px] leading-[52px] font-boldText mt-6 sm:mt-4 sm:text-sm sm:font-regularText sm:ml-1">
                  Will be launched on CTIO
                </div>
              </div>
              <img
                src={img}
                alt="bgRight"
                className="max-w-[453px] max-h-[259px] md:absolute md:right-16 sm:right-4 md:h-52 sm:hidden"
              />
            </div>
            <div className="rounded-2xl bg-[rgba(255,255,255,0.75)] pt-[40px] px-[36px] pb-20 sm:mt-[45px] sm:px-4 sm:pt-8 sm:pb-[52px]">
              <div className="text-[34px] leading-[52px] font-boldText sm:text-[26px] sm:leading-[30px]">
                Projects details
              </div>
              <div className="text-[#4F4F4F] leading-[22px] mt-5 sm:mt-6 sm:text-[16px] sm:leading-[22px]">
                Epic War is a 3D blockchain cinematic game and collectibles,
                offering a game, comic book, film, and NFTs all at once. It has
                an open game build for all players, meaning that players may
                create avatars, weapons, gears, and exclusive battle maps. They
                can also create their guilds and conquer other planets in an
                open-world game using blockchain technology. Its core chain is
                the Binance Smart Chain, while its other integrated chains are
                Solana, Polygon, and Near.
              </div>
              <div className="text-[#4F4F4F] leading-[22px] mt-5 sm:text-[16px] sm:leading-[22px]">
                This is a co-op strategy role-play action game with a
                free-to-play-to-earn model, in which players use their heroes to
                keep the new world safe from the evil overlords and their
                henchmen. Epic War is a fast-paced online multiplayer shooting
                game in which you may advance by fulfilling tasks in PvE mode or
                battling other players in PvP or PvPvE mode.
              </div>
              <hr className="border-[#E0E0E0] mt-[42px] hidden sm:block" />
              <div className="flex justify-between px-4 mt-6 sm:mt-[42px] sm:grid sm:grid-cols-1 sm:px-0">
                {[
                  { title: 'Currency', value: 'BUSD' },
                  { title: 'Launch Date', value: '2022-05-12' },
                  { title: 'Total Raise', value: '$696.00' },
                  { title: 'Type', value: 'IDO' },
                  { title: 'Min Allocation', value: '$0.1' },
                  { title: 'Max Allocation ', value: '$23.00' },
                ].map(x => (
                  <div className="sm:flex justify-between items-center text-sm sm:h-5 sm:mb-6 last:sm:mb-0 sm:text-base">
                    <div className="text-[#828282] ">{x.title}</div>
                    <div className="mt-[6px] font-numberText sm:m-0 sm:font-boldText">
                      {x.value}
                    </div>
                  </div>
                ))}
              </div>
              <hr className="border-[#E0E0E0] mt-[50px] sm:hidden" />
              <div className="text-[28px] leading-[52px] mt-6 sm:mt-[42px] sm:text-[24px] sm:leading-[28px]">
                Project tasks
              </div>
              <div className="mt-[17px] flex gap-5 justify-center sm:grid sm:grid-cols-1 sm:mt-6">
                {[
                  {
                    title: 'Vote to establish a bee protection committee',
                    taskPrice: '36 BNB',
                    endTime: '2022-05-09',
                  },
                  {
                    title: 'Vote to establish a bee protection committee',
                    taskPrice: '36 BNB',
                    endTime: '2022-05-09',
                  },
                  {
                    title: 'Vote to establish a bee protection committee',
                    taskPrice: '36 BNB',
                    endTime: '2022-05-09',
                  },
                ].map(x => (
                  <div className="bg-white rounded-2xl border border-solid border-[#F2F2F2] p-6 sm:p-5">
                    <div className="leading-[19px] sm:text-[16px] sm:leading-[19px]">
                      {x.title}
                    </div>
                    <div className="flex justify-between mt-8 h-[54px] sm:mt-5">
                      <div className="grid grid-cols-2">
                        <div className="text-[#828282] text-[14px] leading-4">
                          Task price
                        </div>
                        <div className="font-numberText text-[#4F4F4F] sm:text-[16px]">
                          {x.taskPrice}
                        </div>
                        <div className="text-[#828282] text-[14px] leading-4">
                          End Time
                        </div>
                        <div className="font-numberText text-[#4F4F4F] text-[12px]">
                          {x.endTime}
                        </div>
                      </div>
                      <button className="rounded-[30px] bg-[#DBEEFF] px-[10px] text-[12px] h-[32px] text-[#1E94FC]">
                        Support
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-[28px] leading-[52px] mt-6 sm:mt-[42px] sm:text-[24px] sm:leading-[28px]">
                Epic War key features
              </div>
              <div className="mt-3 text-[22px] leading-[26px] sm:mt-[23px]">
                EpicDAO
              </div>
              <div className="mt-[15px] leading-[22px] sm:mt-[17px] sm:text-[16px] text-[#4F4F4F]">
                The Epic War collectible NFT game aspires to be a universe
                created by the community. The EpicDAO Council will have a
                dedicated voting mechanism via which the Council will be able to
                influence critical protocol choices and affect the course of the
                game. When a new value is created, commissioned SubDAOs will get
                $EWAR tokens, which they may utilize to build on this platform.
              </div>
            </div>
            <div className="mt-9 rounded-2xl bg-[rgba(255,255,255,0.75)] pt-[40px] px-[36px] pb-[66px] sm:mt-6 sm:pt-[32px] sm:px-4 sm:pb-[28px] sm:text-[16px] text-[#4F4F4F] leading-[22px]">
              <div className="text-[34px] leading-[52px] font-boldText sm:text-[26px] sm:leading-[38px] text-[#000]">
                How to join the Epic War IDO on CTIO?
              </div>
              <div className="mt-[10px]">
                Are you a newbie and have never participated in the IDO deals on
                CTIO? In this comprehensive tutorial, you will find everything
                you need to know to activate the tier on CTIO and start
                investing in deals on our platform. Here are several steps to
                get started:
              </div>
              <div className="mt-5 ">
                Create a wallet
                <br />
                Register and complete KYC (Know-Your-Customer procedure)
                <br />
                Activate your tier on CTIO:
                <br />
                Buy $CTIO tokens
                <br />
                Lock-in $CTIO tokens on the platform
                <br />
                Join the IDO deal on CTIO!
              </div>
              <div className="mt-5 ">
                You can also staking and farm your $CTIO tokens to activate your
                tier. Read more in this step-by-step guide to investing with
                CTIO.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <Footer />
      </div>
    </>
  )
}

export default ProjectsDetails
